// We want to keep the order of keys based on their value, not alphabetic one
/* eslint-disable */
export const breakpoints = {
  sm: 320,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 2024
};
/* eslint-enable */

export const offsets = {
  desktop: 106,
  mobile: 85
};
