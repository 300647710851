<template>
  <picture :class="['Image', { 'Image--contain': contain }]" v-if="(media || src) && !genericSource">
    <source :sizes="sizes" :srcset="generatedSrcSet" :type="`image/${type}`" v-if="generatedSrcSet && sizes" />
    <img
      :alt="alt ?? ''"
      :class="['Image', { 'Image--contain': contain }]"
      :fetchpriority="fetchPriority"
      :height="height"
      :loading="loading"
      :src="src"
      :width="width"
      @error="loadDefault"
      v-if="media || src"
    />
  </picture>
  <div class="Image__container" v-else>
    <img
      :alt="$t('ux.atoms.image.defaultImage')"
      :height="height"
      :width="width"
      class="Image__default"
      src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E"
    />
    <svg aria-hidden="true" class="Image__default-svg">
      <use :xlink:href="defaultIcon" />
    </svg>
  </div>
</template>

<script setup lang="ts">
import { breakpoints } from '~/helpers/breakpoints';
import { imageSizes, imgxSizes } from '~/helpers/imageSizes';

import { type mediaType } from './types';

interface ImageProps {
  alt?: null | string;
  contain?: boolean;
  decorative?: boolean;
  fetchPriority?: 'auto' | 'high' | 'low';
  height?: number | string;
  icon?: string;
  loading?: 'eager' | 'lazy';
  media?: mediaType;
  preload?: boolean;
  src?: string;
  srcSet?: null | string;
  type?: string;
  width?: number | string;
}

const props = withDefaults(defineProps<ImageProps>(), {
  alt: '',
  contain: false,
  decorative: false,
  fetchPriority: 'low',
  height: 100,
  icon: undefined,
  loading: 'lazy',
  media: null,
  preload: false,
  src: undefined,
  srcSet: null,
  type: 'webp',
  width: 100
});

// Fallback order : (FANDB-184)
// 1. mobile (<768) = 346px
// 2. desktop/tablet (>768) = 2048
// 3. fallback desktop/tablet = 3000
// 4. fallback desktop/tablet 2 = 1024

const findFormat = ({ format }) => {
  return Number(format.substring(0, format.indexOf('x')));
};

const generatedSrcSet = computed(() => {
  if (props.srcSet) {
    return props.srcSet;
  }
  // mobile 768
  const mobileFormat = props.media?.formats?.find((format) => {
    return findFormat(format) === imageSizes.sm;
  });
  const mobileSrcSet = `${mobileFormat?.path} ${breakpoints.md}`;

  const sizesToTest = [imageSizes.lg, imageSizes.xl, imageSizes.md];

  let desktopFormat;
  sizesToTest.some((size) => {
    return props.media?.formats?.find((format) => {
      desktopFormat = format;
      return findFormat(format) === size;
    });
  });

  const desktopSrcSet = desktopFormat?.path;

  if (desktopFormat?.path || mobileFormat?.path) {
    return mobileSrcSet.concat(', ', desktopSrcSet) || null;
  }
  return null;
});

const defaultIcon = computed(() => {
  return `/global-sprites.svg#${props.icon ? props.icon : 'accor'}`;
});

// all-inclusive_apps/packages/all-inclusive-app/domains/ux/atoms/AiImage/AiImage.vue
const sizes = computed(() => {
  const sizesCollection = [];

  for (const size of Object.keys(breakpoints) as ['lg' | 'md' | 'sm' | 'xl']) {
    sizesCollection.push(`(max-width: ${breakpoints[size]}px) ${imageSizes[size]}px`);
  }

  // Default sizes
  sizesCollection.push(`${imageSizes.defaultSize}px`);

  // Fallbacks
  sizesCollection.push(`${imageSizes.fallback1}px`);
  sizesCollection.push(`${imageSizes.fallback2}px`);

  return sizesCollection.join(', ');
});

const genericSource = ref<boolean>(false);

const loadDefault = () => {
  genericSource.value = true;
};

const returnMatchingMedia = (w: string) => {
  let media = '';

  switch (Number(w.trim().replace('w', ''))) {
    case imgxSizes.sm:
      media = '(max-width: 767px)';
      break;
    case imgxSizes.lg:
      media = '(min-width: 768px)';
      break;
    case imgxSizes.xl:
      media = '(min-width: 1024px)';
      break;
    case imgxSizes.xxl:
      media = '(min-width: 2048px)';
      break;
    default:
      media = undefined;
  }

  return media;
};

const previewLinks = computed(() => {
  const links = [];

  if (!props.preload || !props.srcSet) return [];

  props.srcSet.split(',').forEach((imageSet) => {
    links.push({
      as: 'image',
      imagesrcset: imageSet.substring(0, imageSet.indexOf(' ')),
      media: returnMatchingMedia(imageSet.substring(imageSet.indexOf(' '), imageSet.length)), // to match with format
      rel: 'preload'
    });
  });

  return links;
});

useHead(() => ({ link: previewLinks.value }));
</script>

<style lang="scss" scoped>
@use 'Image';
</style>
