/* eslint-disable */
export const imageSizes = {
  sm: 346,
  md: 346,
  lg: 2048,
  xl: 2048,
  xxl: 2048,
  defaultSize: 2048,
  fallback1: 3000,
  fallback2: 1024
};
/* eslint-enable */

/* eslint-disable */
export const imgxSizes = {
  sm: 346,
  md: 768,
  lg: 1024,
  xl: 2048,
  xxl: 3000
};
/* eslint-enable */

export const smallSrc = (src?: null | string, srcSet?: null | string): string | undefined => {
  return srcSet?.substring(0, srcSet?.indexOf('346w')).trim() ?? src ?? undefined;
};
